<template>
  <router-view></router-view>
</template>
<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: 'App'
})
</script>

<style lang="scss">
//@import "./resources/scss/style";
@import "src/scss/style.scss";
</style>
