<template>
  <!--header-->
  <nav class="navbar fixed-top navbar-nav-scroll">
    <div class="container-fluid">
      <div class="header">
        <div class="brand">
          <img src="https://mythemestore.com/beehive-preview/wp-content/themes/beehive/assets/images/logo.svg"
               alt="">
        </div>
        <div class="right-side">
          <a href="#" class="cart btn-cart">
            <img src="/assets/icon/shopping-bag.svg" alt="">
          </a>
          <a href="#">Login</a>

          <div class="side-bar">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
    </div>
  </nav>
  <!--header-->
</template>

<script>


export default {
  components: {}
}

</script>