<template>
  <div class="photos-page">


    <div class="row">
      <div class="col-lg-8 ">
        <div class="photos-gallery col-glry">
          <div class="tab-header">
            <a href="#" class="title">All Videos</a>
          </div>

          <div class="filter-section">
            <div class="search-header">
              <input type="search" class="form-control shadow-none " placeholder="Search media">
              <button type="button" class="btn btn-login">
                <img src="/assets/icon/search.svg" alt="">
              </button>
            </div>
          </div>

          <div class="gallery-content">
            <ul class="photos-wrapper">
              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">0:03</span>
                    <img src="/assets/images/video-thumbnail-1.jpg"
                         alt="" class="img-fluid">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">1:03</span>
                    <img
                        src="/assets/images/video-thumbnail-2.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AvisoCopa-250x250.png?1670249756"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">2:43</span>
                    <img
                        src="/assets/images/video-thumbnail-3.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/E1CC42DD-71E1-4A1C-BAB8-81F0C19DC8BA-250x250.png?1670253338"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">0:47</span>
                    <img
                        src="/assets/images/video-thumbnail-4.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/4/2021/10/maxresdefault-1-250x250.jpg?1670253338"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">0:30</span>
                    <img
                        src="/assets/images/video-thumbnail-5.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">0:10</span>
                    <img
                        src="/assets/images/video-thumbnail-6.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_3025485-2-250x250.jpeg?1670249756"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">02:03</span>
                    <img
                        src="/assets/images/video-thumbnail-7.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_45379584-250x250.jpeg?1670249756"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">0:09</span>
                    <img
                        src="/assets/images/video-thumbnail-8.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_47028711-250x250.jpeg?1670249756"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

              <li>
                <a href="#" class="article">
                  <div class="thumbnail">
                    <div class="play-btn">
                      <i class="las la-play"></i>
                    </div>
                    <span class="video-time">0:09</span>
                    <img
                        src="/assets/images/video-thumbnail-9.jpg"
                        alt="">
                  </div>
                  <div class="info">
                    <div class="avatar">
                      <img
                          src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_3314259-1-250x250.jpeg?1670249756"
                          alt="">
                    </div>
                    <h4 class="name">User</h4>
                  </div>

                </a>
              </li>

            </ul>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="widget-wrapper">
          <div class="widget">
            <h5 class="title">
              Member
            </h5>
            <div class="items-list">
              <a href="#"> Newest</a>
              <span class="bp-separator" role="separator">|</span>
              <a href="#"> Active</a>
              <span class="bp-separator" role="separator">|</span>
              <a href="#">Popular</a>
            </div>


            <div class="item">
              <div class="avatar">
                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                     alt="">
              </div>
              <div class="info">
                <div class="name">User</div>
                <div class="time">active 7 minutes ago</div>
              </div>
            </div>

            <div class="item">
              <div class="avatar">
                <img
                    src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/1/6005b5c352343-bpthumb.png"
                    alt="">
              </div>
              <div class="info">
                <div class="name">Sephorioth</div>
                <div class="time">active 7 minutes ago</div>
              </div>
            </div>

            <div class="item">
              <div class="avatar">
                <img
                    src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/4/5e2cc15a94152-bpthumb.jpg"
                    alt="">
              </div>
              <div class="info">
                <div class="name">alexa</div>
                <div class="time">active 7 hours ago</div>
              </div>
            </div>


            <div class="item">
              <div class="avatar">
                <img
                    src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/4/5e2cc15a94152-bpthumb.jpg"
                    alt="">
              </div>
              <div class="info">
                <div class="name">jennifer</div>
                <div class="time">active 30 minutes ago</div>
              </div>
            </div>

            <div class="item">
              <div class="avatar">
                <img
                    src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/14/5e2d01291b6b9-bpthumb.jpg"
                    alt="">
              </div>
              <div class="info">
                <div class="name">User</div>
                <div class="time">active 2 hours ago</div>
              </div>
            </div>

            <div class="item">
              <div class="avatar">
                <img
                    src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/6/5e2cccd55f95b-bpthumb.jpg"
                    alt="">
              </div>
              <div class="info">
                <div class="name">Sophia lee</div>
                <div class="time">active 2 weeks ago</div>
              </div>
            </div>

            <div class="item">
              <div class="avatar">
                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                     alt="">
              </div>
              <div class="info">
                <div class="name">User</div>
                <div class="time">active 7 minutes ago</div>
              </div>
            </div>


          </div>
          <div class="footer-widget">
            <a href="#">Home</a>
            <a href="#">About us</a>
            <a href="#">FAQs</a>
            <a href="#">Blog</a>
            <a href="#">Contact</a>
          </div>
        </div>
      </div>
    </div>
  </div>


</template>

<script>

export default {
  components: {},
  methods: {},
  mounted() {
  }
}
</script>
