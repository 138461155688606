<template>
  <div class="row">
    <div class="col-lg-8 ">
      <div class="photos-gallery col-glry">
        <div class="tab-header">
          <a href="#" class="title">All photos</a>
        </div>

        <div class="filter-section">
          <div class="search-header">
            <input type="search" class="form-control shadow-none " placeholder="Search media">
            <button type="button" class="btn btn-login">
              <img src="/assets/icon/search.svg" alt="">
            </button>
          </div>
        </div>

        <div class="gallery-content">
          <ul class="photos-wrapper">
            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/12/solidarite-800x578.jpg?1670250359"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/12/ComputerDesktopWallpapersCollection1571__013-250x250.jpg?1670249756"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AvisoCopa-250x250.png?1670249756"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/hd-wallpaper-g145e731ae_1920-250x250.jpg?1670253338"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/E1CC42DD-71E1-4A1C-BAB8-81F0C19DC8BA-250x250.png?1670253338"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/zhizn-tsitaty-250x250.jpg?1670253338"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/4/2021/10/maxresdefault-1-250x250.jpg?1670253338"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/12/solidarite-800x578.jpg?1670250359"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>
            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/B077Q4PR53-EBC01-250x250.jpg?1670249756"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_3025485-2-250x250.jpeg?1670249756"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_55403933-250x250.jpeg?1670249756"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_45379584-250x250.jpeg?1670249756"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_13630573-250x250.jpeg?1670249756"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_47028711-250x250.jpeg?1670249756"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_3851682-1-250x250.jpeg?1670249756"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/11/AdobeStock_3314259-1-250x250.jpeg?1670249756"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>

            <li>
              <a href="#" class="article">
                <div class="thumbnail">
                  <img
                      src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/12/ComputerDesktopWallpapersCollection1571__013-250x250.jpg?1670249756"
                      alt="">
                </div>
                <div class="info">
                  <div class="avatar">
                    <img
                        src="https://mythemestore.com/beehive-preview/wp-content/uploads/rtMedia/users/3/2022/10/013B06F2-5084-4ABE-9329-96C0549590BD-250x250.jpeg?1670253338"
                        alt="">
                  </div>
                  <h4 class="name">User</h4>
                </div>

              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-lg-4">
      <div class="widget-wrapper">
        <div class="widget">
          <h5 class="title">
            Member
          </h5>
          <div class="items-list">
            <a href="#"> Newest</a>
            <span class="bp-separator" role="separator">|</span>
            <a href="#"> Active</a>
            <span class="bp-separator" role="separator">|</span>
            <a href="#">Popular</a>
          </div>


          <div class="item">
            <div class="avatar">
              <img
                  src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                  alt="">
            </div>
            <div class="info">
              <div class="name">User</div>
              <div class="time">active 7 minutes ago</div>
            </div>
          </div>

          <div class="item">
            <div class="avatar">
              <img
                  src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/1/6005b5c352343-bpthumb.png"
                  alt="">
            </div>
            <div class="info">
              <div class="name">Sephorioth</div>
              <div class="time">active 7 minutes ago</div>
            </div>
          </div>

          <div class="item">
            <div class="avatar">
              <img
                  src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/4/5e2cc15a94152-bpthumb.jpg"
                  alt="">
            </div>
            <div class="info">
              <div class="name">alexa</div>
              <div class="time">active 7 hours ago</div>
            </div>
          </div>


          <div class="item">
            <div class="avatar">
              <img
                  src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/4/5e2cc15a94152-bpthumb.jpg"
                  alt="">
            </div>
            <div class="info">
              <div class="name">jennifer</div>
              <div class="time">active 30 minutes ago</div>
            </div>
          </div>

          <div class="item">
            <div class="avatar">
              <img
                  src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/14/5e2d01291b6b9-bpthumb.jpg"
                  alt="">
            </div>
            <div class="info">
              <div class="name">User</div>
              <div class="time">active 2 hours ago</div>
            </div>
          </div>

          <div class="item">
            <div class="avatar">
              <img
                  src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/6/5e2cccd55f95b-bpthumb.jpg"
                  alt="">
            </div>
            <div class="info">
              <div class="name">Sophia lee</div>
              <div class="time">active 2 weeks ago</div>
            </div>
          </div>

          <div class="item">
            <div class="avatar">
              <img
                  src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                  alt="">
            </div>
            <div class="info">
              <div class="name">User</div>
              <div class="time">active 7 minutes ago</div>
            </div>
          </div>


        </div>
        <div class="footer-widget">
          <a href="#">Home</a>
          <a href="#">About us</a>
          <a href="#">FAQs</a>
          <a href="#">Blog</a>
          <a href="#">Contact</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

