<template>
  <div class="row">
            <div class="col-lg-8 ">
                <div class="photos-gallery col-glry">
                    <div class="tab-header">
                        <a href="#" class="title">Active members <span class="badge">15</span> </a>
                    </div>
                    <!--filter section-->
                    <div class="filter-section">
                        <div class="search-header ">
                            <input type="search" class="form-control shadow-none " placeholder="Search members...">
                            <button type="button" class="btn btn-login">
                              <img src="/assets/icon/search.svg" alt="">
                            </button>
                        </div>
                        <div class="select-section">
                            <select class="form-select" aria-label="Default select example">
                                <option selected>Last active</option>
                                <option value="1">Newest Registered</option>
                                <option value="2">Alphabetical</option>
                            </select>
                        </div>
                    </div>


                    <div class="members-list-wrapper">
                        <ul class="member-list">
                            <li>
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">User</a>
                                    </h4>
                                    <p class="last-activity">Active 13 minutes ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">23</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/1/6005b5c330c63-bpfull.png"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">Sephiroth</a>
                                    </h4>
                                    <p class="last-activity">Active 4, weeks 3 days ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">23</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap" >
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/4/5e2cc15a922f4-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">Irina petrova</a>
                                    </h4>
                                    <p class="last-activity">Active 13 minutes ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">23</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap" >
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/16/5e2d07dbc43f2-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">Jennie Ferguson</a>
                                    </h4>
                                    <p class="last-activity">Active 13 minutes ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">23</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li >
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/14/5e2d01291542c-bpfull.jpg" class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">John thompson</a>
                                    </h4>
                                    <p class="last-activity">Active 30 minutes ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">1</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/6/5e2cccd55d57d-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">Sophia lee</a>
                                    </h4>
                                    <p class="last-activity">Active 3 days ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">3</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">5</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/12/5e2cfd5d18ea0-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">Alexa</a>
                                    </h4>
                                    <p class="last-activity">Active 1 years ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">12</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">43</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/9/5e2ce3f5b95cd-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">Brian walton</a>
                                    </h4>
                                    <p class="last-activity">Active 6 hours ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">23</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/13/5e2cfee616ad3-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">Julia coxs</a>
                                    </h4>
                                    <p class="last-activity">Active 2 years, 4 months ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">23</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>

                            <li>
                                <div class="list-wrap">
                                    <div class="avatar-wrap">
                                        <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpfull.jpg"
                                             class="img-fluid" alt="">
                                    </div>
                                    <h4 class="avatar-name">
                                        <a href="#" class="">User</a>
                                    </h4>
                                    <p class="last-activity">Active 13 minutes ago</p>
                                    <div class="connection-wrapper">
                                        <ul class="connections">
                                            <li><span class="number">23</span>
                                                <p>Friends</p>
                                            </li>
                                            <li><span class="number">4</span>
                                                <p>Group</p>
                                            </li>

                                        </ul>
                                    </div>
                                    <div class="option">
                                        <button type="button" class="btn btn-outline-theme ">View profile</button>
                                    </div>
                                </div>
                            </li>


                        </ul>
                    </div>
                </div>
            </div>

            <!--right side widget-->
            <div class="col-lg-4">
                <div class="widget-wrapper">
                    <div class="widget">
                        <h5 class="title">
                            Member
                        </h5>
                        <div class="items-list">
                            <a href="#"> Newest</a>
                            <span class="bp-separator" role="separator">|</span>
                            <a href="#"> Active</a>
                            <span class="bp-separator" role="separator">|</span>
                            <a href="#">Popular</a>
                        </div>


                        <div class="item">
                            <div class="avatar">
                                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                                     alt="">
                            </div>
                            <div class="info">
                                <div class="name">User</div>
                                <div class="time">active 7 minutes ago</div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="avatar">
                                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/1/6005b5c352343-bpthumb.png"
                                     alt="">
                            </div>
                            <div class="info">
                                <div class="name">Sephorioth</div>
                                <div class="time">active 7 minutes ago</div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="avatar">
                                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/4/5e2cc15a94152-bpthumb.jpg"
                                     alt="">
                            </div>
                            <div class="info">
                                <div class="name">alexa</div>
                                <div class="time">active 7 hours ago</div>
                            </div>
                        </div>


                        <div class="item">
                            <div class="avatar">
                                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/4/5e2cc15a94152-bpthumb.jpg"
                                     alt="">
                            </div>
                            <div class="info">
                                <div class="name">jennifer</div>
                                <div class="time">active 30 minutes ago</div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="avatar">
                                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/14/5e2d01291b6b9-bpthumb.jpg"
                                     alt="">
                            </div>
                            <div class="info">
                                <div class="name">User</div>
                                <div class="time">active 2 hours ago</div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="avatar">
                                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/6/5e2cccd55f95b-bpthumb.jpg"
                                     alt="">
                            </div>
                            <div class="info">
                                <div class="name">Sophia lee</div>
                                <div class="time">active 2 weeks ago</div>
                            </div>
                        </div>

                        <div class="item">
                            <div class="avatar">
                                <img src="https://mythemestore.com/beehive-preview/wp-content/uploads/avatars/3/1670101867-bpthumb.jpg"
                                     alt="">
                            </div>
                            <div class="info">
                                <div class="name">User</div>
                                <div class="time">active 7 minutes ago</div>
                            </div>
                        </div>


                    </div>
                    <div class="footer-widget">
                        <a href="#">Home</a>
                        <a href="#">About us</a>
                        <a href="#">FAQs</a>
                        <a href="#">Blog</a>
                        <a href="#">Contact</a>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {}
</script>
